@media only screen and (max-width: 600px) {
    .header-container {
        padding: 0px 20px !important;
        width: calc(100% - 40px) !important;
    }

    .header-menu {
        display: none !important;
    }
}

.header-container {
    position: absolute;
    pointer-events: all;
    top: 0px;
    width: calc(100% - 200px);
    height: 80px;
    display: grid;
    flex-direction: row;
    align-items: center;
    z-index: 15;
    padding: 0px 100px;
    grid-template-columns: 25% 50% 25%;

    .header-name {
        text-align: left;
        grid-column: 1;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 400;
        font-size: 2em;
    }

    .header-menu {
        grid-column: 2;
        display: flex;
        justify-content: space-evenly;

        .item {
            width: 10px;
            height: 10px;
            background: white;
        }
    }

    .header-socialButtons {
        display: flex;
        flex-direction: row;
        grid-column: 3;
        gap: 20px;
        justify-self: flex-end;
    }

    .menu-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        .menu-button {
            width: 20px;
            height: 20px;
            cursor: pointer;
            border-radius: 100%;
            background: rgba(0, 0, 0, 0.5);
            transition: 0.1s ease;

            &.true {
                transform: scale(1.5);

                &:hover {
                    transform: scale(1.5);
                }
            }

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}
