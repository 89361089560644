.sectionheader-container {
    position: relative;
    top: 0px;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding-top: 30px;

    .sectionheader-name {
        display: flex;
        text-align: left;
        flex-direction: row;
        text-transform: lowercase;
        grid-column: 1;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 400;
        font-size: 2em;
        gap: 2px;

        .sectionheader-header {
            font-family: 'Bebas Neue', cursive;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;
            transform: translate(0px, -5px);
            letter-spacing: 2px;
        }
    }
}
