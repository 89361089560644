.content-container {
    position: absolute;
    top: 100vh;
    width: 100%;
    height: fit-content;
    background: #f9fafb;
    z-index: 20;

    @media only screen and (max-width: 600px) {
        .collection {
            display: flex !important;
            flex-direction: column;
            padding: 20px !important;
        }
    }

    .collection {
        padding: 0px 100px 100px 100px;
        justify-content: center;

        &.software_engineer,
        &.student {
            display: grid;
            grid-template-columns: repeat(auto-fill, 400px);
            column-gap: 20px;
            row-gap: 30px;
            height: fit-content;
        }

        &.photographer {
            display: -webkit-box; /* Not needed if autoprefixing */
            display: -ms-flexbox; /* Not needed if autoprefixing */
            display: flex;
            width: auto;
            column-gap: 20px;
            row-gap: 30px;
        }

        .my-masonry-grid_column {
            gap: 30px;
            display: flex;
            flex-direction: column;
        }
    }

    .collection-item {
        background: white;
        box-shadow: 0px 10px 15px #00000029;
        border: 1px solid #e5e7eb;
        border-radius: 10px;

        &.photographer {
            overflow: hidden;
            width: fit-content;
            height: fit-content;

            .photo {
                object-fit: cover;
                height: 100%;
                width: 100%;
                transform: scale(1.1);
                transition: ease 0.1s;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        &.student {
            display: grid;
            padding: 20px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        &.software_engineer {
            display: grid;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .project-image {
                object-fit: cover;
                height: 200px;
                width: 100%;
            }

            .project-title {
                padding: 20px 20px 0px 20px;
                font-size: 14px;
                color: black;
            }

            .project-description {
                padding: 0px 20px;
                font-size: 10px;
                color: #6f6f6f;
            }

            .project-technologies {
                padding: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-self: flex-end;
                gap: 5px;

                .project-technology {
                    border-radius: 5px;
                    font-size: 10px;
                    color: #6f6f6f;
                    background: #e2e2e2;
                    padding: 3px 7px;
                    width: fit-content;
                }
            }
        }
    }
}
