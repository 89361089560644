.socialButton-container {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;

    .socialButton-border {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: solid 2px;
        transition: ease-in 0.3s;
        transform: scale(0.3);
        opacity: 0;
    }

    .socialButton-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(1.1);
        transition: ease-in 0.1s;

        &.linkedin {
            transform: scale(0.8);
        }
    }

    &:hover {
        .socialButton-border {
            opacity: 1;
            transform: scale(1);
        }

        .socialButton-logo {
            transform: scale(0.9);
            &.linkedin {
                transform: scale(0.6);
            }
        }
    }
}
