.App {
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
        display: none; /* Safari and Chrome */
    }
}

.contentScroll-container {
    color: white;
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;

    .contentScroll-caret {
        transform: translate(0px, -10px);
    }
}

.info {
    font-family: 'Bebas Neue', cursive;
    position: absolute;
    top: calc(50% - 25px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    text-align: center;
    z-index: 10;
    user-select: none;
}

.background {
    position: absolute;
    pointer-events: none;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: 0;
    overflow: hidden;

    .gradient {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .grain {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.aboutMe-button {
    font-family: 'Josefin Sans', sans-serif;
    transform: rotate(270deg);
    position: absolute;
    bottom: 50px;
    left: 50px;
    z-index: 30;
    padding: 3px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    .toggle {
        position: absolute;
        left: 110%;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        display: flex;

        .vertical,
        .horizontal {
            position: absolute;
            width: 10px;
            height: 2px;
            transition: 0.2s ease;
            transition-property: transform;
        }

        .vertical {
            transform: rotate(270deg);

            &.false {
                transform: rotate(45deg);
            }
        }

        .horizontal.false {
            transform: rotate(-45deg);
        }
    }

    .background {
        z-index: -1;
        position: absolute;
        background: black;
        height: 100%;
        transition: 0.5s ease;
        transform: translate(-5px, 0px);
        transition-property: width;
    }

    &:hover {
        .background {
            width: 100%;
        }
    }
}

.aboutMe-container {
    transition: 0.5s ease;
    transition-property: bottom;
    display: flex;
    position: absolute;
    width: 200px;
    z-index: 10;
    left: 120px;
}

@media only screen and (max-width: 600px) {
    .aboutMe-button {
        display: none;
    }
}
